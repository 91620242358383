<template>
	<div class="form-group-image">
		<div class="label mb-2">
			{{ title }}
		</div>
		<small
			v-if="subTitle"
			class="form-text text-muted w-100 mb-2"
		>
			{{ subTitle }}
		</small>
		<div class="d-flex" :class="{ 'is-invalid': isError }">
			<div class="image-link">
				<a
					href="#"
					@click.prevent="handleClickLogo"
				>
					<div v-if="!logo" class="image-placeholder">
						<img src="/assets/images/product-placeholder.svg" alt="Add image" class="mb-1">
						Add image
					</div>
					<img
						v-else
						:src="logo.imageUrls.thumbnail"
						class="image-preview"
						alt=""
					>
				</a>
				<CButton
					v-if="logo"
					class="btn-delete-image"
					@click="handleDeleteLogo"
				>
					<CIcon name="cil-trash" />
				</CButton>
			</div>
			<ul class="image-helper pl-4">
				<li>- Recommended resolution W{{ recommendResolution }} x H{{ recommendResolution }} px</li>
				<li>- Maximum upload size per file is 10MB</li>
				<li>- Supported PNG, JPG, JPEG, GIF, Webp</li>
			</ul>
		</div>
		<div class="form-group-image-error invalid-feedback mb-3">
			{{ $t('global.error.required') }}
		</div>
		<ModalMediaLibrary
			ref="media-library"
			type="single"
			@onSubmit="handleSubmitLogo"
		/>
	</div>
</template>

<script>
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';

export default {
	name: 'UploadLogo',

	components: {
		ModalMediaLibrary,
	},
	props: {
		logo: {
			type: Object,
			default: null,
		},
		title: {
			type: String,
			default: 'Channel logo',
		},
		subTitle: {
			type: String,
			default: '',
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		isError: {
			type: Boolean,
			default: false,
		},
		recommendResolution: {
			type: String,
			default: '64',
		},
	},
	methods: {
		handleClickLogo() {
			this.$refs['media-library'].open();
		},
		handleSubmitLogo(files) {
			this.$emit('update:logo', files[0]);
		},
		handleDeleteLogo() {
			this.$emit('update:logo', null);
		},
	},
};
</script>


<style lang="scss" scoped>
	.image-placeholder {
		@include typo-helper-text;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: rem(78);
		height: rem(78);
		background-color: $color-gray-100;
		border: rem(1) solid $color-gray-300;
		border-radius: rem(2);
		color: $color-black-45;

		img {
			width: rem(32);
			height: rem(32);
		}
	}

	.image-helper {
		list-style: none;
		margin: 0;

		li {
			@include typo-body-2;

			color: $color-black-45;
		}
	}

	.image-link {
		position: relative;
		display: block;

		a {
			&:hover {
				text-decoration: none;
			}
		}
	}

	.image-preview {
		width: rem(78);
		height: rem(78);
		border: rem(1) solid $color-gray-300;
		border-radius: rem(2);
		object-fit: contain;
	}

	.form-group-image {
		a {
			text-decoration: none;
		}

		.is-invalid {
			.image-placeholder,
			.image-preview {
				border-color: $color-alert;
			}
		}
	}

	.form-group-image-error {
		display: block;
		visibility: hidden;
	}

	.is-invalid ~ .invalid-feedback {
		visibility: visible;
	}

	.btn-delete-image {
		position: absolute;
		right: rem(8);
		bottom: rem(8);
		width: rem(20);
		height: rem(20);
		box-shadow: none;
		padding: 0;
		border-radius: rem(2);
		background-color: $color-black-45;

		svg {
			width: rem(12) !important;
			color: $color-white;
		}
	}
</style>
