<template>
	<div class="form-container">
		<h2 class="typo-h4 mb-4">Product bundle condition</h2>
		<BundleSetConditionItem
			v-for="(vCondition, _, itemIndex) in $v.formData.conditions.$each.$iter"
			:key="itemIndex"
			:v-condition="vCondition"
			:index="itemIndex"
			:is-able-to-remove-condition="isAbleToRemoveCondition"
			:class="{ 'mt-5': itemIndex > 0 }"
			@onRemoveCondition="handleRemoveCondition"
			@onAddProductCondition="handleAddProductCondition"
			@onChangeRule="handleChangeRule"
			@onChangeDisplay="handleChangeDisplay"
		/>
		<a
			v-if="formData.conditions.length < 20"
			href="#"
			class="mt-4 d-inline-block"
			data-test-id="bundle-set-btn-add-condition"
			@click.prevent="handleAddCondition"
		>
			Add new group of SKUs
		</a>

		<!-- Modals -->
		<ModalProductCondition
			ref="modal-product-condition"
			:is-edit="isEditProductCondition"
			:default-data="selectedProductConditionForModal"
			:custom-key-label="CONDITION_KEY_LABEL"
			:custom-key-options="CONDITION_KEY_OPTIONS"
			:custom-rule-options="CONDITION_TYPE_OPTIONS"
			no-condition-match
			no-subkey
			@onRemove="handleRemoveProductCondition"
			@onConfirm="handleChangeProductCondition"
		/>

		<BaseModalConfirm
			ref="modal-confirm-remove-condition"
			:title="removeStepConditionModalTitle"
			class="modal-confirm-remove-condition"
			description="Are you sure you want to delete this bundle group?"
			primary-button-text="Remove"
			@onConfirm="handleStepCondition"
		/>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, requiredIf, minLength, maxLength } from 'vuelidate/lib/validators';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import BundleSetConditionItem from '@/components/BundleSetConditionItem.vue';
import ModalProductCondition from '@/components/ModalProductCondition.vue';
import {
	BUNDLE_SET_RULE_KEY_OPTIONS,
	BUNDLE_SET_RULE_RULE_OPTIONS,
	BUNDLE_SET_DISPLAY_TYPE,
	CONDITION_KEY_LABEL,
	CONDITION_KEY_OPTIONS,
	CONDITION_TYPE_OPTIONS,
} from '../enums/bundleSet';

const defaultCondition = {
	name: null,
	isRequired: false,
	productConditions: [],
	rule: {
		key: BUNDLE_SET_RULE_KEY_OPTIONS?.[0] ?? null,
		rule: BUNDLE_SET_RULE_RULE_OPTIONS?.[0] ?? null,
		value: null,
	},
	display: {
		type: BUNDLE_SET_DISPLAY_TYPE.PRIMARY_SKU,
		primarySku: null,
		specificNameEn: null,
		specificNameTh: null,
		specificNameImage: null,
	},
};

export default {
	name: 'BundleSetStepCondition',

	components: {
		BundleSetConditionItem,
		ModalProductCondition,
		BaseModalConfirm,
	},

	validations: {
		formData: {
			conditions: {
				required,
				minLength: minLength(2),
				$each: {
					name: {
						required,
						maximum255Characters: maxLength(255),
					},
					isRequired: {},
					productConditions: {
						required,
					},
					rule: {
						key: {
							required,
						},
						rule: {
							required,
						},
						value: {
							required,
							minimumValue1: (value) => value > 0,
						},
					},
					display: {
						type: {
							required,
						},
						primarySku: {
							required: requiredIf((vm) => vm.type === BUNDLE_SET_DISPLAY_TYPE.PRIMARY_SKU),
						},
					},
				},
			},
		},
	},

	mixins: [validationMixin],

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const hasConditions = this.defaultData?.conditions && this.defaultData?.conditions.length >= 2;
		const conditions = hasConditions
			? this.defaultData.conditions
			: [{ ...defaultCondition }, { ...defaultCondition }]; // NOTE: Default 2 conditions

		return {
			selectedRemoveConditionIndex: null,
			formData: {
				conditions,
			},
			currentAddingProductConditionGroupIndex: null,
			CONDITION_KEY_LABEL,
			CONDITION_KEY_OPTIONS,
			CONDITION_TYPE_OPTIONS,
		};
	},

	computed: {
		isAbleToRemoveCondition() {
			return this.formData.conditions.length > 2;
		},

		selectedProductCondition() {
			if (this.currentAddingProductConditionGroupIndex !== null) {
				return this.formData.conditions?.[this.currentAddingProductConditionGroupIndex]?.productConditions ?? null;
			}
			return null;
		},

		selectedProductConditionForModal() {
			if (this.selectedProductCondition?.length) {
				return { conditions: this.selectedProductCondition };
			}
			return null;
		},

		isEditProductCondition() {
			return this.selectedProductCondition?.length > 0 ?? false;
		},

		removeStepConditionModalTitle() {
			return `Remove SKUs for Group ${this.selectedRemoveConditionIndex + 1}`;
		},
	},

	watch: {
		formData: {
			deep: true,
			handler(formData) {
				this.$emit('onChanged', {
					valid: !this.$v.$invalid,
					formData,
				});
			},
		},
	},

	methods: {
		handleAddCondition() {
			this.formData.conditions = [
				...this.formData.conditions,
				{ ...defaultCondition },
			];
		},

		handleRemoveCondition(index) {
			this.selectedRemoveConditionIndex = index;
			this.$refs['modal-confirm-remove-condition'].open();
		},

		handleStepCondition() {
			this.formData.conditions = this.formData.conditions.filter((_, itemIndex) => itemIndex !== this.selectedRemoveConditionIndex);
		},

		handleAddProductCondition(index) {
			this.currentAddingProductConditionGroupIndex = index;
			this.$nextTick(() => {
				this.$refs['modal-product-condition'].open();
			});
		},

		handleChangeProductCondition(condition = {}) {
			// Replace product condition data
			this.$set(this.formData.conditions, this.currentAddingProductConditionGroupIndex, {
				...this.formData.conditions[this.currentAddingProductConditionGroupIndex],
				productConditions: condition?.conditions ?? [],
			});

			// Clear group index
			this.currentAddingProductConditionGroupIndex = null;
		},

		handleRemoveProductCondition() {
			this.handleChangeProductCondition({
				conditions: [],
			});
		},

		handleChangeRule({ index, value, key }) {
			this.$set(this.formData.conditions, index, {
				...this.formData.conditions[index],
				rule: {
					...this.formData.conditions[index].rule,
					[key]: value,
				},
			});
		},


		handleChangeDisplay({ index, value, key }) {
			this.$set(this.formData.conditions, index, {
				...this.formData.conditions[index],
				display: {
					...this.formData.conditions[index].display,
					[key]: value,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);
	}

	::v-deep .modal-confirm-remove-condition {
		.btn-primary {
			background-color: $color-alert;
			color: $color-white;
		}
	}
</style>
