<template>
	<li class="condition-item mb-3">
		<div class="condition-item-link">
			<div v-if="!noConditionMatch" class="condition-item-info mr-3">
				<span class="condition-item-label typo-label">{{ type }}</span>
			</div>
			<ul class="condition-item-detail">
				<ProductConditionListItem
					v-for="(condition, index) in list"
					:key="`list-condition-${index}`"
					:condition="condition"
				/>
			</ul>
			<div
				v-if="isShowEditButton"
				class="condition-item-actions"
			>
				<button type="button" class="btn icon-edit" @click.prevent="handleEditClick">
					<CIcon name="cil-pencil" />
				</button>
			</div>
		</div>
	</li>
</template>

<script>
import ProductConditionListItem from '@/components/ProductConditionListItem.vue';

export default {
	name: 'ProductConditionList',

	components: {
		ProductConditionListItem,
	},

	props: {
		defaultData: {
			type: Object,
			default: () => ({}),
		},
		noConditionMatch: {
			type: Boolean,
			default: false,
		},
		isShowEditButton: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		type() {
			return this.defaultData.type;
		},
		list() {
			if (!this.defaultData || !this.defaultData.conditions) {
				return [];
			}

			return this.defaultData.conditions;
		},
	},

	methods: {
		handleEditClick() {
			this.$emit('onEdit');
		},
	},
};
</script>

<style lang="scss" scoped>
	.condition-item {
		display: block;
		border-radius: 4px;
		border: 1px solid $color-gray-400;
		background: $color-white;

		// .condition-item-link
		&-link {
			@include typo-body-2;

			display: flex;
			padding: rem(20) rem(16) rem(12);
			text-decoration: none;
		}

		// .condition-item-info
		&-info {
			flex: 0 0 rem(28);
		}

		// .condition-item-detail
		&-detail {
			flex: 1;
			list-style: none;
			padding: 0;
			margin: 0;
		}

		// .condition-item-actions
		&-actions {
			flex: 0 0 rem(40);
			text-align: center;
			margin-top: rem(-8);

			.icon-edit {
				color: $color-gray-500;

				&:hover {
					color: $color-orange;
				}
			}
		}
	}
</style>
