<template>
	<CRow
		class="sub-condition-item mt-2 mb-3"
	>
		<CCol md="3" class="sub-condition-rule pr-1">
			<BaseDropDown
				:value="selectedType"
				:options="subConditionKeyOptions"
				:disabled="!primaryConditionType"
				:searchable="false"
				:allow-empty="false"
				:is-valid="!condition.type.$error"
				:invalid-feedback="$t('global.error.required')"
				class="select-custom my-0 ml-4"
				label="label"
				track-by="value"
				placeholder="Select sub-key"
				@input="handleTypeChange"
			/>
		</CCol>
		<CCol md="3" class="pl-2 pr-1">
			<BaseDropDown
				v-if="!isTypeAttribute"
				:value="selectedRule"
				:options="subConditionRuleOptions"
				:disabled="!primaryConditionType || !condition.type.$model"
				:searchable="false"
				:allow-empty="false"
				:is-valid="!condition.rule.$error"
				:invalid-feedback="$t('global.error.required')"
				class="select-custom my-0"
				label="label"
				track-by="value"
				@input="handleRuleChange"
			/>
		</CCol>
		<CCol md="5" class="pl-2">
			<BaseInputNumber
				v-if="isTypeQuantity"
				v-model="condition.value.$model"
				:is-valid="!condition.value.$error"
				:invalid-feedback="$t('global.error.required')"
				append-text="Qty."
				text-align="left"
				placeholder="0"
				@input="handleValueChange"
			/>
			<BaseInputNumber
				v-else-if="isTypePurchaseAmount"
				v-model="condition.value.$model"
				:is-valid="!condition.value.$error"
				:invalid-feedback="$t('global.error.required')"
				:decimal-scale="2"
				prepend-text="฿"
				text-align="right"
				placeholder="0.00"
				@input="handleValueChange"
			/>
		</CCol>
		<CCol md="1">
			<CButton
				class="btn-delete p-0 color-black-25"
				data-test-id="delete-button"
				@click.prevent="handleRemoveCondition"
			>
				<CIcon
					class="icon-trash"
					name="cil-trash"
				/>
			</CButton>
		</CCol>
		<CCol v-if="isTypeAttribute" md="12">
			<ProductSubConditionAttribute
				:key="primaryConditionValueKey"
				:v="v"
				:primary-condition-type="primaryConditionType"
				:primary-condition-value="primaryConditionValue"
				:match-type="condition.rule"
				:sub-conditions="condition.subConditions"
			/>
		</CCol>
	</CRow>
</template>

<script>
import ProductSubConditionAttribute from '@/components/ProductSubConditionAttribute.vue';

import {
	CONDITION_KEYS,
	CONDITION_RULE_OPTIONS,
	SUB_CONDITION_KEY_OPTIONS,
	CONDITION_RULE_KEYS,
	CONDITION_TYPES,
} from '../enums/promotions';

export default {
	name: 'ProductSubConditionItem',

	components: {
		ProductSubConditionAttribute,
	},

	props: {
		v: {
			type: Object,
			required: true,
		},
		primaryConditionType: {
			type: String,
			default: null,
		},
		primaryConditionValue: {
			type: Array,
			default: null,
		},
		condition: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			CONDITION_KEYS,
		};
	},

	computed: {
		subConditionKeyOptions() {
			if (this.primaryConditionType === CONDITION_KEYS.SKU) {
				return SUB_CONDITION_KEY_OPTIONS.filter((option) => option.value !== CONDITION_KEYS.ATTRIBUTE);
			}

			return SUB_CONDITION_KEY_OPTIONS;
		},
		subConditionRuleOptions() {
			if (this.condition.type.$model) {
				return this.getRuleOptions(this.condition.type.$model);
			}

			return [];
		},
		// Use to be key of attribute component
		// If primary values was changed, it will trigger force re-renderring
		primaryConditionValueKey() {
			return this.primaryConditionValue
				? this.primaryConditionValue.map(({ id }) => id).join(',')
				: 'primaryConditionValue';
		},
		selectedType() {
			return this.getSelectedType(this.condition.type.$model);
		},
		selectedRule() {
			return this.getSelectedRule(this.condition.rule.$model, this.condition.type.$model);
		},
		isTypePurchaseAmount() {
			return [CONDITION_KEYS.PURCHASE_AMOUNT, CONDITION_KEYS.TOTAL_PURCHASE_AMOUNT].includes(this.condition.type.$model);
		},
		isTypeQuantity() {
			return [CONDITION_KEYS.QUANTITY, CONDITION_KEYS.TOTAL_QUANTITY].includes(this.condition.type.$model);
		},
		isTypeAttribute() {
			return this.condition.type.$model === CONDITION_KEYS.ATTRIBUTE;
		},
	},

	methods: {
		getSelectedType(selectedKey = null) {
			const findStatus = ({ value }) => value === selectedKey;
			return Object.values(SUB_CONDITION_KEY_OPTIONS).find(findStatus);
		},
		getSelectedRule(selectedRule = null, selectedKey = null) {
			const findStatus = ({ value }) => value === selectedRule;
			const ruleOptions = this.getRuleOptions(selectedKey);
			return Object.values(ruleOptions).find(findStatus);
		},
		getRuleOptions(selectedKey = null) {
			if (selectedKey) {
				return CONDITION_RULE_OPTIONS[selectedKey];
			}

			return [];
		},
		handleTypeChange(event) {
			this.condition.type.$model = event.value;
			this.condition.rule.$model = null;
			this.condition.value.$model = null;
			this.condition.subConditions.$model = [];

			if (this.condition.type.$model === CONDITION_KEYS.ATTRIBUTE) {
				this.condition.rule.$model = CONDITION_TYPES.ALL; // Force rule for Match All
				this.condition.subConditions.$model = [
					{
						type: null,
						rule: CONDITION_RULE_KEYS.IS_CONTAIN, // Force rule for Contain
						value: null,
					},
				];
			}

			this.v.$reset();
		},
		handleRuleChange(event) {
			this.condition.rule.$model = event.value;

			this.v.$reset();
		},
		handleValueChange() {
			this.v.$reset();
		},
		handleRemoveCondition() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
	.sub-condition-rule {
		padding-left: rem(24);

		&::before {
			content: "";
			width: rem(4);
			border-radius: rem(2.5);
			background-color: $color-black-25;
			position: absolute;
			display: block;
			height: 100%;
			max-height: rem(34);
			top: 0;
			left: rem(28);
		}
	}
</style>
