<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			size="lg"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="`${isEdit ? 'Edit' : 'Add'} condition`"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow v-if="!noConditionMatch" class="mt-2">
					<CCol md="8">
						<label>Condition match</label>
					</CCol>
					<CCol md="4" class="text-right">
						<CInputRadioGroup
							:options="CONDITION_TYPE_OPTIONS"
							:checked.sync="localType"
							:custom="true"
							:inline="true"
							size="sm"
							class="radio-group"
							name="modal-product-condition-type"
						/>
					</CCol>
				</CRow>
				<CRow
					v-for="(validateItem, index) in $v.localList.$each.$iter"
					:key="`params${index}`"
					class="mt-2"
				>
					<CCol md="3" class="pr-1">
						<BaseDropDown
							:value="getSelectedKey(validateItem.type.$model)"
							:options="CONDITION_KEY_OPTIONS"
							:searchable="false"
							:allow-empty="false"
							:is-valid="!validateItem.type.$error"
							:invalid-feedback="$t('global.error.required')"
							class="select-custom mb-2"
							label="label"
							track-by="value"
							label-drop-down="Key"
							@input="handleKeyChange(index, $event)"
						/>
					</CCol>
					<CCol md="3" class="pl-2 pr-1">
						<BaseDropDown
							:value="getSelectedRule(validateItem.rule.$model, validateItem.type.$model)"
							:options="getRuleOptions(validateItem.type.$model)"
							:searchable="false"
							:allow-empty="false"
							:disabled="!validateItem.type.required"
							:is-valid="validateItem.type.$model && !validateItem.rule.$error"
							:invalid-feedback="$t('global.error.required')"
							class="select-custom mb-2"
							label="label"
							track-by="value"
							label-drop-down="Rule"
							@input="handleRuleChange(index, $event)"
						/>
					</CCol>
					<CCol md="5" class="pl-2">
						<div class="condition-value">
							<label>Value</label>
							<div v-if="!validateItem.type.required || !validateItem.rule.required" class="empty-value">
								<span>Select value</span>
							</div>
							<div v-else :class="{ 'is-invalid': validateItem.type.$model && validateItem.rule.$model && validateItem.value.$error }">
								<CButton
									type="button"
									class="btn mr-3"
									color="secondary"
									@click="handleValueClick(index)"
								>
									{{ validateItem.value.$model ? 'Edit' : 'Add' }} {{ CONDITION_KEY_LABELS[validateItem.type.$model] }}
								</CButton>
								<span>{{ getSummaryOfValue(validateItem.type.$model, validateItem.value.$model) }}</span>
							</div>
							<div
								v-if="validateItem.type.$model && validateItem.rule.$model && validateItem.value.$error"
								class="invalid-feedback d-block"
							>
								{{ $t('global.error.required') }}
							</div>
						</div>
					</CCol>
					<CCol v-if="index > 0" md="1">
						<CButton
							class="btn-delete p-0"
							@click.prevent="handleRemoveCondition(index)"
						>
							<CIcon
								class="icon-trash"
								name="cil-trash"
							/>
						</CButton>
					</CCol>
					<CCol md="12" class="pt-0 px-0">
						<ProductSubCondition
							v-if="!noSubkey"
							:v="$v"
							:primary-condition-type="validateItem.type.$model"
							:primary-condition-value="validateItem.value.$model"
							:sub-conditions="validateItem.subConditions"
						/>
					</CCol>
					<CCol v-if="isMultipleCondition"><hr></CCol>
				</CRow>
				<div v-if="isMultipleCondition" class="mb-4">
					<a
						href="#"
						class="btn-add-new"
						@click.prevent="handleAddNewCondition"
					>
						Add new key
					</a>
				</div>

				<ModalSkuList
					ref="modal-sku"
					title="Select SKUs"
					:sku-list="selectedValues"
					@onConfirm="handleValueListChange"
				/>

				<ModalBrand
					ref="modal-brand"
					title="Select Brands"
					:list="selectedValues"
					@onConfirm="handleValueListChange"
				/>

				<ModalCategory
					ref="modal-category"
					title="Select categories"
					:selected-ids="selectedValues"
					@onSubmit="handleValueListChange"
				/>
			</template>
			<template #footer>
				<a
					v-if="isEdit"
					href="#"
					class="btn-remove mr-auto"
					@click.prevent="handleRemove"
				>
					Remove conditon
				</a>
				<CButton
					class="modal-button transparent mr-3"
					color="tertiary"
					data-test-id="cancel-button"
					@click="close"
				>
					Cancel
				</CButton>
				<CButton
					:disabled="$v.$invalid"
					class="modal-button transparent"
					color="primary"
					data-test-id="primary-button"
					@click="handleConfirm"
				>
					Done
				</CButton>
			</template>
		</CModal>
	</div>
</template>


<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import ProductSubCondition from '@/components/ProductSubCondition.vue';
import ModalSkuList from '@/components/ModalSkuList.vue';
import ModalBrand from '@/components/ModalBrand.vue';
import ModalCategory from '@/components/ModalCategory.vue';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import { numberFormat, cloneDeep } from '../assets/js/helpers';
import {
	CONDITION_TYPE_OPTIONS,
	CONDITION_TYPES,
	CONDITION_KEYS,
	CONDITION_KEY_LABELS,
	CONDITION_KEY_OPTIONS,
	CONDITION_RULE_KEYS,
	CONDITION_RULE_OPTIONS,
	CONDITION_RULE_TYPE_ALL_OPTIONS,
} from '../enums/promotions';

export default {
	name: 'ModalProductCondition',
	components: {
		ProductSubCondition,
		ModalHeaderWrapper,
		ModalSkuList,
		ModalBrand,
		ModalCategory,
	},
	validations() {
		return {
			localList: {
				required,
				minLength: minLength(1),
				$each: {
					type: {
						required,
					},
					rule: {
						required: requiredIf((vm) => vm.type),
					},
					value: {
						required: requiredIf((vm) => vm.rule),
					},
					subConditions: {
						$each: {
							type: {
								required,
							},
							rule: {
								required: requiredIf((subCondition) => subCondition.type),
							},
							value: {
								required: requiredIf((subCondition) => subCondition.type && subCondition.type !== CONDITION_KEYS.ATTRIBUTE),
							},
							// For sub condition of Attribute
							subConditions: {
								$each: {
									type: {
										required,
									},
									rule: { required },
									value: {
										required: requiredIf((subCondition) => subCondition.type),
									},
								},
							},
						},
					},
				},
			},
		};
	},
	mixins: [validationMixin],
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		defaultData: {
			type: Object,
			default: () => ({}),
		},
		isRuleTypeAll: {
			type: Boolean,
			default: false, // false = is one of / is not one of, true = all of / is not all of
		},
		noConditionMatch: {
			type: Boolean,
			default: false,
		},
		noSubkey: {
			type: Boolean,
			default: false,
		},
		isMultipleCondition: {
			type: Boolean,
			default: false,
		},
		customKeyLabel: {
			type: Object,
			default: null,
		},
		customKeyOptions: {
			type: Array,
			default: null,
		},
		customRuleOptions: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			CONDITION_TYPE_OPTIONS,
			CONDITION_KEYS,
			CONDITION_KEY_LABELS: this.customKeyLabel ?? CONDITION_KEY_LABELS,
			CONDITION_KEY_OPTIONS: this.customKeyOptions ?? CONDITION_KEY_OPTIONS,
			isShow: false,
			selectedIndex: -1,
			localList: null,
			localType: null,
		};
	},
	computed: {
		selectedValues() {
			if (this.selectedIndex && this.selectedIndex < 0) { return []; }

			let values = null;
			const conditionKey = this.localList[this.selectedIndex].type;
			switch (conditionKey) {
				case CONDITION_KEYS.SKU:
					values = this.localList[this.selectedIndex].value || [];
					break;
				case CONDITION_KEYS.BRAND:
					values = (this.localList[this.selectedIndex].value || []).map(({ id, name }) => ({ id, name }));
					break;
				case CONDITION_KEYS.CATEGORY:
					values = (this.localList[this.selectedIndex].value || []).map(({ id }) => (id));
					break;
				case CONDITION_KEYS.MAIN_CATEGORY:
					values = (this.localList[this.selectedIndex].value || []).map(({ id }) => (id));
					break;
				default: break;
			}
			return values;
		},
	},
	methods: {
		...mapActions({
			preSelectCategories: 'categorySelector/preSelectCategories',
		}),
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		numberFormat,
		getSelectedKey(selectedKey = null) {
			const findStatus = ({ value }) => value === selectedKey;
			return Object.values(this.CONDITION_KEY_OPTIONS).find(findStatus);
		},
		getSelectedRule(selectedRule = null, selectedKey = null) {
			const findStatus = ({ value }) => value === selectedRule;
			const ruleOptions = this.getRuleOptions(selectedKey);
			return Object.values(ruleOptions).find(findStatus);
		},
		getRuleOptions(selectedKey = null) {
			if (selectedKey) {
				if (this.customRuleOptions) {
					return this.customRuleOptions[selectedKey];
				}
				return this.isRuleTypeAll ? CONDITION_RULE_TYPE_ALL_OPTIONS[selectedKey] : CONDITION_RULE_OPTIONS[selectedKey];
			}

			return [];
		},
		getSummaryOfValue(type, value) {
			if (!type && !value) {
				return '';
			}

			// No selected any value
			if (type && !value) {
				return `No ${this.CONDITION_KEY_LABELS[type]} selected`;
			}

			// Fully summary
			if (Array.isArray(value)) {
				return `${numberFormat(value.length)} ${this.CONDITION_KEY_LABELS[type]}(s) selected`;
			}

			// Implicit value
			return value;
		},
		resetState() {
			const { type, conditions } = cloneDeep(this.defaultData) || {};
			let defaultConditionData = {
				type: null, // UI, key
				rule: null, // UI, rule
				value: null, // UI, values
			};

			// Default first option (key & rule) if those options only has one list
			if (this.CONDITION_KEY_OPTIONS.length === 1) {
				const defaultKey = this.CONDITION_KEY_OPTIONS?.[0]?.value ?? null;
				let defaultRule = null;

				const ruleOptions = this.getRuleOptions(defaultKey);
				if (ruleOptions.length === 1) {
					defaultRule = ruleOptions?.[0]?.value ?? null;
				}

				defaultConditionData = {
					type: defaultKey,
					rule: defaultRule,
					value: null,
				};
			}

			this.localType = type || CONDITION_TYPES.ALL;
			this.localList = conditions || [{ ...defaultConditionData }];

			// TODO: Re-check in transformer
			// Initial sub condition
			this.localList = this.localList.map((condition) => ({
				...condition,
				subConditions: condition.subConditions || [],
			}));

			this.$v.$reset();
		},
		handleRemove() {
			this.isShow = false;
			this.$emit('onRemove');
		},
		handleConfirm() {
			this.isShow = false;
			this.$emit(
				'onConfirm',
				{
					type: this.localType,
					conditions: this.localList,
				},
			);
		},
		handleKeyChange(index, event) {
			if (index >= 0) {
				this.localList[index].type = event.value;
				this.localList[index].rule = null;
				this.localList[index].value = null;

				this.$v.$reset();
			}
		},
		handleRuleChange(index, event) {
			if (index >= 0) {
				const condition = this.localList[index];
				condition.rule = event.value;
				this.$set(this.localList, index, condition);

				this.$v.$reset();
			}
		},
		async handleValueClick(index) {
			if (index < 0) { return; }

			this.selectedIndex = index;
			// wait for renderring new selected list, props of modal
			await this.$nextTick();

			const conditionKey = this.localList[index].type;
			switch (conditionKey) {
				case CONDITION_KEYS.SKU:
					this.$refs['modal-sku'].open();
					break;
				case CONDITION_KEYS.BRAND:
					this.$refs['modal-brand'].open();
					break;
				case CONDITION_KEYS.CATEGORY:
					this.preSelectCategories(this.selectedValues);
					this.$refs['modal-category'].open();
					break;
				case CONDITION_KEYS.MAIN_CATEGORY:
					this.preSelectCategories(this.selectedValues);
					this.$refs['modal-category'].open();
					break;
				default: break;
			}
		},
		handleValueListChange(list) {
			let values = null;
			const conditionKey = this.localList[this.selectedIndex].type;
			switch (conditionKey) {
				case CONDITION_KEYS.SKU:
					values = list;
					break;
				case CONDITION_KEYS.BRAND:
					values = list.map((item) => ({ id: item.id, name: item.name }));
					break;
				case CONDITION_KEYS.CATEGORY:
					values = list.map((item) => ({ id: item.id, name: item.name }));
					break;
				case CONDITION_KEYS.MAIN_CATEGORY:
					values = list.map((item) => ({ id: item.id, name: item.name }));
					break;
				default: break;
			}

			this.localList[this.selectedIndex].value = values;

			// Reset attriute when any values was changed
			this.localList[this.selectedIndex].subConditions.forEach((subCondition) => {
				if (subCondition.type === CONDITION_KEYS.ATTRIBUTE) {
					this.$set(subCondition, 'subConditions', [
						{
							type: null,
							rule: CONDITION_RULE_KEYS.IS_CONTAIN, // Force rule for Contain
							value: null,
						},
					]);
				}
			});

			// reset
			this.selectedIndex = -1;
		},
		handleAddNewCondition() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.localList.push({
					type: null,
					rule: null,
					value: null,
					subConditions: [],
				});
			}
		},
		handleRemoveCondition(index) {
			this.selectedIndex = -1;
			if (index >= 0) {
				this.$delete(this.localList, index);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-delete {
		margin-top: rem(32);

		.icon-trash {
			color: $color-black-25;
			width: rem(18);
			height: rem(18);
		}
	}

	.condition-value {
		span {
			line-height: rem(34);
		}

		.empty-value {
			height: rem(34);
			padding: 0 rem(12);
			color: $color-black-25;
			background-color: $color-gray-200;
			border-radius: rem(4);
		}

		.is-invalid {
			border: 1px solid $color-alert;
			border-radius: rem(4);
		}
	}
</style>
