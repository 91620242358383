<template>
	<div class="d-flex align-items-center justify-content-between">
		<div class="d-flex flex-column">
			<h6 class="mb-0">SKUs for Group {{ `${index + 1}` }}</h6>
			<p class="typo-caption color-black-45 mb-0">In each group must have product sku.</p>
		</div>
		<div class="d-flex align-items-center">
			<CButton
				v-if="isAbleToRemoveCondition"
				class="transparent btn-remove"
				data-test-id="bundle-set-btn-remove-condition"
				@click="$emit('onRemoveCondition', index)"
			>
				<CIcon
					name="cil-trash"
					size="custom"
					custom-classes="c-icon c-icon-custom-size icon-trash"
				/>
				<span>Remove this group</span>
			</CButton>
			<CButton
				color="secondary"
				data-test-id="bundle-set-btn-add-product"
				@click="$emit('onAddProductCondition', index)"
			>
				{{ buttonAddProductLabel }}
			</CButton>
		</div>
	</div>
</template>

<script>


export default {
	name: 'BundleSetConditionItemHeader',

	props: {
		index: {
			type: Number,
			default: 0,
		},
		isAbleToRemoveCondition: {
			type: Boolean,
			default: false,
		},
		hasProductCondition: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		buttonAddProductLabel() {
			return this.hasProductCondition ? 'Edit product' : 'Add product';
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-remove {
		@include typo-caption-2;

		line-height: rem(24);
		margin: 0 rem(24) 0 0;
		padding: 0 rem(16) 0 0;
		border-radius: 0;
		border-right: 1px solid $color-black-45;
	}

	.icon-trash {
		width: rem(12);
		margin: 0 rem(4) 0 0;
	}
</style>
