<template>
	<div class="page-header mb-4">
		<div>
			<h2 class="page-header-title typo-h4 d-flex align-items-center">
				{{ title }}
				<CBadge
					v-if="status"
					:color="status.color"
					class="badge-status"
					data-test-id="badge-status"
				>
					{{ status.title }}
				</CBadge>
			</h2>
		</div>
		<div class="summary">
			<p class="typo-body-2 m-0 p-0">
				Last updated by: <span class="color-hypertext">{{ updatedBy }}</span>
				<span class="date color-black-45">{{ updatedAt }}</span>
			</p>
			<p class="typo-body-2 m-0">
				Created by: <span class="color-hypertext">{{ createdBy }}</span>
				<span class="date color-black-45">{{ createdAt }}</span>
			</p>
		</div>
	</div>
</template>

<script>

export default {
	name: 'PageHeader',

	props: {
		title: {
			type: String,
			default: '',
		},
		updatedAt: {
			type: String,
			default: '-',
		},
		updatedBy: {
			type: String,
			default: '',
		},
		createdBy: {
			type: String,
			default: '',
		},
		createdAt: {
			type: String,
			default: '-',
		},
		status: {
			type: Object,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
	.page-header-title {
		padding-right: 30%;
		position: relative;
		overflow-wrap: break-word;
	}

	.summary {
		.date {
			&::before {
				content: "|";
				display: inline-block;
				margin-left: rem(5);
				margin-right: rem(5);
			}
		}
	}

	.badge-status {
		width: auto;
		padding: rem(7) rem(8);
		margin-left: rem(16);
		font-size: rem(14);
		position: absolute;
		right: 0;
	}
</style>
