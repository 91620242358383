<template>
	<div class="bundle-set-condition-display">
		<div class="d-flex align-items-center justify-content-between mb-3">
			<div class="mr-3">
				<p class="label mb-1">Item displaying for this group</p>
				<p class="typo-caption color-black-45 mb-0">This will be displayed on Product detail page</p>
			</div>
			<div>
				<CInputRadioGroup
					:checked="vCondition.display.type.$model"
					:options="displayTypeOptions"
					:name="`display-type-${index}`"
					size="sm"
					class="radio-group"
					data-test-id="bundle-set-display-type"
					custom
					inline
					@update:checked="(value) => $emit('onChange', { key: 'type', value })"
				/>
			</div>
		</div>

		<!-- Primary SKU -->
		<div v-if="isTypePrimarySku">
			<CInput
				:value="vCondition.display.primarySku.$model"
				:is-valid="!vCondition.display.primarySku.$error && null"
				:invalid-feedback="getErrorMessage(vCondition.display.primarySku)"
				label="Primary SKU*"
				placeholder="Please enter SKU"
				class="mb-0"
				data-test-id="bundle-set-display-primary-sku"
				@input="(value) => $emit('onChange', { key: 'primarySku', value })"
			/>
		</div>

		<!-- Specific display -->
		<div v-else-if="isTypeSpecificDisplay">
			<!-- TODO: -->
		</div>
	</div>
</template>

<script>
import { getErrorMessage } from '../assets/js/validators';
import { BUNDLE_SET_DISPLAY_TYPE, BUNDLE_SET_DISPLAY_TYPE_OPTIONS } from '../enums/bundleSet';

export default {
	name: 'BundleSetConditionItemDisplay',

	props: {
		// Validation item
		vCondition: {
			type: Object,
			default: null,
		},
		index: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			displayTypeOptions: BUNDLE_SET_DISPLAY_TYPE_OPTIONS,
		};
	},

	computed: {
		isTypePrimarySku() {
			return this.vCondition.display.type.$model === BUNDLE_SET_DISPLAY_TYPE.PRIMARY_SKU;
		},

		isTypeSpecificDisplay() {
			return this.vCondition.display.type.$model === BUNDLE_SET_DISPLAY_TYPE.SPECIFIC_DISPLAY;
		},
	},

	methods: {
		getErrorMessage(validateItem) {
			const { key, data } = getErrorMessage(validateItem) ?? {};
			if (key) {
				return this.$t(key, data);
			}
			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
	.bundle-set-condition-display {
		padding: rem(16);
		border-radius: rem(4);
		border: rem(1) solid $color-gray-400;
	}
</style>
