<template>
	<div class="bundle-set-condition-rule">
		<CRow>
			<CCol md="4">
				<BaseDropDown
					:value="vCondition.rule.key.$model"
					:options="keyOptions"
					:searchable="false"
					:allow-empty="false"
					:is-valid="!vCondition.rule.key.$error && null"
					:invalid-feedback="getErrorMessage(vCondition.rule.key)"
					class="mb-0"
					label-drop-down="Key"
					label="label"
					track-by="value"
					data-test-id="bundle-set-rule-key"
					@input="(value) => $emit('onChange', { key: 'key', value })"
				/>
			</CCol>
			<CCol md="4">
				<BaseDropDown
					:value="vCondition.rule.rule.$model"
					:options="ruleOptions"
					:searchable="false"
					:allow-empty="false"
					:is-valid="!vCondition.rule.rule.$error && null"
					:invalid-feedback="getErrorMessage(vCondition.rule.rule)"
					class="mb-0"
					label-drop-down="Rule"
					label="label"
					track-by="value"
					data-test-id="bundle-set-rule-rule"
					@input="(value) => $emit('onChange', { key: 'rule', value })"
				/>
			</CCol>
			<CCol md="4">
				<BaseInputNumber
					:value="vCondition.rule.value.$model"
					:is-valid="!vCondition.rule.value.$error"
					:invalid-feedback="getErrorMessage(vCondition.rule.value)"
					label="Value*"
					append-text="Qty."
					text-align="left"
					placeholder="0"
					data-test-id="bundle-set-rule-value"
					@input="(value) => $emit('onChange', { key: 'value', value })"
				/>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { getErrorMessage } from '../assets/js/validators';
import { BUNDLE_SET_RULE_KEY_OPTIONS, BUNDLE_SET_RULE_RULE_OPTIONS } from '../enums/bundleSet';

export default {
	name: 'BundleSetConditionItemRule',

	props: {
		// Validation item
		vCondition: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			keyOptions: BUNDLE_SET_RULE_KEY_OPTIONS,
			ruleOptions: BUNDLE_SET_RULE_RULE_OPTIONS,
		};
	},

	methods: {
		getErrorMessage(validateItem) {
			const { key, data } = getErrorMessage(validateItem) ?? {};
			if (key) {
				return this.$t(key, data);
			}
			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
	.bundle-set-condition-rule {
		padding: rem(16);
		border-radius: rem(4);
		border: rem(1) solid $color-gray-400;

		.row {
			margin-left: rem(-8);
			margin-right: rem(-8);

			.col {
				padding-left: rem(8);
				padding-right: rem(8);
			}
		}
	}
</style>
