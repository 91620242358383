<template>
	<div>
		<div class="d-block mt-4 mb-3">
			<label class="mb-0">
				<h2 class="typo-h4 position-relative">
					Customer group*
				</h2>
			</label>
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				data-test-id="button-add"
				@click="$refs['modal-customer-group'].open()"
			>
				{{ customerGroupTitle }}
			</CButton>
		</div>
		<BaseNoItemBanner
			v-if="!customerGroups.length"
			text="There is no customer group added."
			class="mb-3"
		/>
		<ul v-else class="customer-group-list">
			<li v-for="item in customerGroups" :key="item.id" class="list-item typo-body-2">
				{{ item.name }}
			</li>
		</ul>

		<ModalCustomerGroup
			ref="modal-customer-group"
			:title="customerGroupTitle"
			:list="customerGroups"
			@onConfirm="handleCustomerGroupListChange"
		/>
	</div>
</template>


<script>
import ModalCustomerGroup from '@/components/ModalCustomerGroup.vue';

export default {
	name: 'BundleSetCustomerGroup',
	components: {
		ModalCustomerGroup,
	},
	props: {
		customerGroups: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		customerGroupTitle() {
			return `${this.customerGroups && this.customerGroups.length ? 'Edit' : 'Add'} customer group`;
		},
	},
	methods: {
		handleCustomerGroupListChange(list) {
			let transformList = [];
			if (list && Array.isArray(list)) {
				transformList = list.map(({ id, name }) => ({ id, name }));
			}
			this.$emit('update:customerGroups', transformList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.customer-group-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16) rem(8);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		list-style: none;

		.list-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}
	}
</style>
