<template>
	<li class="condition-item-detail-item typo-body-2">
		{{ CONDITION_KEY_LABELS[condition.type] }} <span class="typo-label">{{ getSummaryOfValue(condition.type, condition.rule, condition.value) }}</span>
		<ul v-if="subConditions.length" class="pl-3 mb-4">
			<ProductSubConditionListItem
				v-for="(subCondition, index) in subConditions"
				:key="`list-sub-condition-${index}`"
				:sub-condition="subCondition"
			/>
		</ul>
	</li>
</template>

<script>
import ProductSubConditionListItem from '@/components/ProductSubConditionListItem.vue';
import {
	CONDITION_KEY_LABELS,
	CONDITION_KEYS,
	CONDITION_RULE_LABELS,
} from '../enums/promotions';

export default {
	name: 'ProductConditionListItem',

	components: {
		ProductSubConditionListItem,
	},

	props: {
		condition: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			CONDITION_KEYS,
			CONDITION_KEY_LABELS,
			CONDITION_RULE_LABELS,
		};
	},

	computed: {
		subConditions() {
			if (this.condition && this.condition.subConditions) {
				return this.condition.subConditions;
			}
			return [];
		},
	},

	methods: {
		getSummaryOfValue(type, rule, items) {
			if (!type && !rule && !items) {
				return '';
			}

			// No selected any value
			if (type && rule && !items) {
				return `No ${CONDITION_KEY_LABELS[CONDITION_KEYS[type]]}`;
			}

			// Fully summary
			if (Array.isArray(items)) {
				let valueText = '';
				const maximumValueList = 5;

				// Get at most 5 items
				let renderList = items;
				if (items.length > maximumValueList) {
					renderList = items.slice(0, maximumValueList);
				}

				// If item is Object { id, name }, get only name to display
				if ([CONDITION_KEYS.BRAND, CONDITION_KEYS.CATEGORY, CONDITION_KEYS.MAIN_CATEGORY].includes(type)) {
					renderList = renderList.map((val) => (val.name));
				}

				if (items.length > maximumValueList) {
					valueText = `${renderList.join(', ')} and ${(items.length - maximumValueList)} more ${CONDITION_KEY_LABELS[CONDITION_KEYS[type]]}(s)`;
				} else {
					valueText = renderList.join(', ');
				}

				return `${CONDITION_RULE_LABELS[rule]} ${valueText}`;
			}

			// Implicit value
			return items;
		},
	},
};
</script>

<style lang="scss" scoped>
	.condition-item-detail-item {
		list-style: none;
		margin-bottom: rem(8);
	}
</style>
