<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<BundleSetForm v-else :default-data="defaultData" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import omit from 'lodash/omit';
import BundleSetForm from '@/components/BundleSetForm.vue';

import { transformedBundleSetToFormData } from '../assets/js/transform/bundleSet';

export default {
	name: 'BundleSetCreate',

	components: {
		BundleSetForm,
	},

	data() {
		return {
			isLoading: true,
			defaultData: null,
		};
	},

	computed: {
		...mapState('bundleSet', {
			edit: 'edit',
		}),
	},

	async created() {
		const bundleSetId = this.$route.query?.ref ?? null;

		if (bundleSetId) {
			await this.getBundleSet(bundleSetId);
			this.extractData();

			this.isLoading = false;
		} else {
			this.isLoading = false;
		}
	},

	methods: {
		...mapActions({
			getBundleSet: 'bundleSet/getBundleSet',
		}),
		extractData() {
			if (this.edit && this.edit.data) {
				this.defaultData = transformedBundleSetToFormData(this.edit.data);
				this.defaultData = {
					...this.defaultData,
					step1: omit(this.defaultData.step1, [
						'id',
						'periodValue',
						'startDate',
						'startTime',
						'endDate',
						'endTime',
					]),
				};
			}
		},
	},
};
</script>
