<template>
	<div class="sub-condition-panel mt-3 pb-3">
		<CCol>
			<ProductSubConditionItem
				v-for="(vCondition, index) in subConditions.$each.$iter"
				:key="`subcondition${index}`"
				:v="v"
				:primary-condition-type="primaryConditionType"
				:primary-condition-value="primaryConditionValue"
				:condition="vCondition"
				@onRemove="handleRemoveSubCondition(index)"
			/>
			<div class="pt-2">
				<a
					href="#"
					class="ml-3"
					data-test-id="add-subkey-button"
					@click.prevent="handleAddNewSubCondition"
				>
					Add sub-key
				</a>
			</div>
		</CCol>
	</div>
</template>

<script>
import ProductSubConditionItem from '@/components/ProductSubConditionItem.vue';

export default {
	name: 'ProductSubCondition',

	components: {
		ProductSubConditionItem,
	},

	props: {
		v: {
			type: Object,
			required: true,
		},
		primaryConditionType: {
			type: String,
			default: null,
		},
		primaryConditionValue: {
			type: Array,
			default: null,
		},
		subConditions: {
			type: [Object, Array],
			default: null,
		},
	},

	methods: {
		handleAddNewSubCondition() {
			this.v.$touch();
			if (this.v.$invalid) {
				return;
			}

			this.subConditions.$model.push({
				type: null, // UI, key
				rule: null, // UI, rule
				value: null, // UI, value
				subConditions: [], // UI, sub key, sub condition
			});
		},
		handleRemoveSubCondition(index) {
			if (index < 0) {
				return;
			}

			this.subConditions.$model.splice(index, 1);
		},
	},
};
</script>
