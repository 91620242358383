<template>
	<li class="sub-condition-item-detail-item mt-2 typo-body-2">
		<template v-if="!isTypeAttribute">
			{{ CONDITION_KEY_LABELS[subCondition.type] }} <span class="typo-label">{{ getSummaryOfValue(subCondition.type, subCondition.rule, subCondition.value) }}</span>
		</template>
		<template v-else>
			<div class="sub-conditoin-attribute">
				<div>{{ CONDITION_KEY_LABELS[subCondition.type] }} <span class="typo-label">{{ CONDITION_TYPE_LABELS[subCondition.rule] }}</span></div>
				<div class="sub-conditoin-attribute-value ml-3">
					<div
						v-for="(attributeCondition, index) in subCondition.subConditions"
						:key="index"
						class="sub-conditoin-attribute-value-item pl-2"
					>
						{{ attributeCondition.type.label }} <span class="typo-label">{{ getSummaryOfValue(attributeCondition.type, attributeCondition.rule, attributeCondition.value) }}</span>
					</div>
				</div>
			</div>
		</template>
	</li>
</template>

<script>
import { numberFormat, priceFormat } from '../assets/js/helpers';
import { CONDITION_KEYS, CONDITION_KEY_LABELS, CONDITION_RULE_LABELS, CONDITION_TYPE_LABELS } from '../enums/promotions';

export default {
	name: 'ProductConditionListItem',

	props: {
		subCondition: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			CONDITION_KEYS,
			CONDITION_KEY_LABELS,
			CONDITION_RULE_LABELS,
			CONDITION_TYPE_LABELS,
		};
	},

	computed: {
		isTypeAttribute() {
			return this.subCondition && this.subCondition.type === CONDITION_KEYS.ATTRIBUTE;
		},
	},

	methods: {
		getSummaryOfValue(type, rule, value) {
			if (!type && !rule && !value) {
				return '';
			}
			let valueText = value;

			if ([CONDITION_KEYS.PURCHASE_AMOUNT, CONDITION_KEYS.TOTAL_PURCHASE_AMOUNT].includes(type)) {
				valueText = priceFormat(value);
			} else if ([CONDITION_KEYS.QUANTITY, CONDITION_KEYS.TOTAL_QUANTITY].includes(type)) {
				valueText = numberFormat(value);
			}

			return `${CONDITION_RULE_LABELS[rule]} ${valueText}`;
		},
	},
};
</script>

<style lang="scss" scoped>
	.sub-condition-item-detail-item {
		list-style: none;
		margin-bottom: rem(8);
	}

	.sub-conditoin-attribute {
		display: flex;

		// .sub-conditoin-attribute-value
		&-value {
			// .sub-conditoin-attribute-value-item
			&-item {
				position: relative;
				margin-bottom: rem(8);

				&:last-child {
					margin-bottom: 0;
				}

				&::before {
					content: "";
					position: absolute;
					width: rem(1);
					height: rem(18);
					background: $color-black-90;
					top: 0;
					left: 0;
				}
			}
		}
	}
</style>
