<template>
	<div class="attribute-panel mt-3 ml-3 py-2 px-3">
		<CRow class="mt-2">
			<CCol md="8">
				<label class="pt-1">Attribute condition match</label>
			</CCol>
			<CCol md="4" class="text-right">
				<CInputRadioGroup
					:options="CONDITION_TYPE_OPTIONS"
					:checked.sync="matchType.$model"
					:custom="true"
					:inline="true"
					:name="`${uid}`"
					size="sm"
					class="radio-group"
				/>
			</CCol>
		</CRow>
		<template>
			<ProductSubConditionAttributeItem
				v-for="(vCondition, index) in subConditions.$each.$iter"
				:key="`attribute-${index}`"
				:v="v"
				:primary-condition-type="primaryConditionType"
				:primary-condition-value="primaryConditionValue"
				:condition="vCondition"
				:attribute-key-options="attributeKeyOptions"
				:show-remove="index > 0"
				class="mt-3"
				@onRemove="handleRemoveSubConditionAttribute(index)"
			/>
		</template>
		<CRow class="mt-3 mb-4">
			<CCol>
				<a
					href="#"
					class="btn-add-new"
					data-test-id="add-new-button"
					@click.prevent="handleAddNewSubConditionAttribute"
				>
					Add new attribute
				</a>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapGetters } from 'vuex';
import ProductSubConditionAttributeItem from '@/components/ProductSubConditionAttributeItem.vue';
import {
	CONDITION_TYPE_OPTIONS,
	CONDITION_KEYS,
	CONDITION_RULE_KEYS,
} from '../enums/promotions';

export default {
	name: 'ProductSubConditionAttribute',

	components: {
		ProductSubConditionAttributeItem,
	},

	props: {
		v: {
			type: Object,
			required: true,
		},
		primaryConditionType: {
			type: String,
			default: null,
		},
		primaryConditionValue: {
			type: Array,
			default: null,
		},
		matchType: {
			type: Object,
			default: null,
		},
		subConditions: {
			type: [Object, Array],
			default: null,
		},
	},

	data() {
		return {
			CONDITION_KEYS,
			CONDITION_TYPE_OPTIONS,
			uid: null,
		};
	},

	computed: {
		...mapGetters({
			attributeKeys: 'attributeKeys/attributeList',
		}),
		attributeKeyOptions() {
			return this.attributeKeys
				? this.attributeKeys.map((option) => (
					{
						label: option.name,
						value: option.id,
					}
				))
				: [];
		},
	},

	mounted() {
		this.uid = uniqueId('sub-condition-attribute-condition-type-');
	},
	async created() {
		await this.fetchAttributeKeys();
	},

	methods: {
		...mapActions({
			getAttributeKeys: 'attributeKeys/getAttributeKeys',
		}),
		async fetchAttributeKeys() {
			if (isEmpty(this.primaryConditionValue)) {
				return;
			}

			const filterValue = this.primaryConditionValue.map(({ id }) => id).join(',');
			const isPrimaryKeyBrand = this.primaryConditionType === CONDITION_KEYS.BRAND;
			const filterObject = isPrimaryKeyBrand
				? { brand_ids: filterValue }
				: { category_ids: filterValue };

			const queryParams = {
				...filterObject,
				per_page: 'all',
			};

			await this.getAttributeKeys(queryParams);
		},
		handleAddNewSubConditionAttribute() {
			this.subConditions.$touch();

			if (!this.subConditions.$invalid) {
				this.subConditions.$model.push({
					type: null,
					rule: CONDITION_RULE_KEYS.IS_CONTAIN, // Force rule for contain
					value: null,
				});
			}
		},
		handleRemoveSubConditionAttribute(index) {
			if (index < 0) { return; }

			this.subConditions.$model.splice(index, 1);
		},
	},
};
</script>

<style lang="scss" scoped>
	.attribute-panel {
		background-color: $color-gray-100;
		border-radius: rem(4);
	}
</style>
