<template>
	<CRow>
		<CCol md="4" class="pr-1">
			<BaseDropDown
				:value="selectedType"
				:options="attributeKeyOptions"
				:searchable="true"
				:allow-empty="false"
				:is-valid="!condition.type.$error"
				:invalid-feedback="$t('global.error.required')"
				class="select-custom my-0"
				label="label"
				track-by="value"
				placeholder="Select attribute"
				@input="handleTypeChange"
			/>
		</CCol>
		<CCol md="2" class="pl-2 pr-1">
			<span class="typo-body2 d-block mt-2">contains</span>
		</CCol>
		<CCol md="5" class="pl-2">
			<CInput
				v-model.trim="condition.value.$model"
				:is-valid="!condition.value.$error && null"
				:invalid-feedback="$t('global.error.required')"
				placeholder="Add value"
				class="my-0"
				@input="handleValueChange"
			/>
		</CCol>
		<CCol v-if="showRemove" md="1">
			<CButton
				class="btn-delete mt-1 p-0 color-black-25"
				@click.prevent="handleRemoveCondition"
			>
				<CIcon
					class="icon-trash"
					name="cil-trash"
				/>
			</CButton>
		</CCol>
	</CRow>
</template>

<script>
import { CONDITION_KEYS } from '../enums/promotions';

export default {
	name: 'ProductSubConditionAttributeItem',

	props: {
		v: {
			type: Object,
			required: true,
		},
		primaryConditionType: {
			type: String,
			default: null,
		},
		primaryConditionValue: {
			type: Array,
			default: null,
		},
		condition: {
			type: [Object, Array],
			required: true,
		},
		attributeKeyOptions: {
			type: Array,
			required: true,
		},
		showRemove: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			CONDITION_KEYS,
		};
	},

	computed: {
		selectedType() {
			return this.getSelectedType(this.condition.type.$model);
		},
	},

	methods: {
		getSelectedType(selectedKey) {
			const findStatus = ({ value }) => value === (selectedKey || {}).value;
			return Object.values(this.attributeKeyOptions).find(findStatus);
		},
		handleTypeChange(event) {
			this.condition.type.$model = event;

			this.condition.$reset();
		},
		handleValueChange() {
			this.condition.$reset();
		},
		handleRemoveCondition() {
			this.$emit('onRemove');
		},
	},
};
</script>
