<template>
	<div>
		<PageHeader
			v-if="isEdit"
			:title="bundleInfo.name"
			:updated-at="bundleInfo.updatedAt"
			:updated-by="bundleInfo.updatedBy"
			:created-at="bundleInfo.createdAt"
			:created-by="bundleInfo.createdBy"
			:status="bundleInfo.period"
		/>
		<div class="tabs-container-large mb-5 pb-5">
			<div v-if="steps" class="tabs-wrapper">
				<CTabs
					:active-tab="currentStepIndex"
					@update:activeTab="handleUpdateTab"
				>
					<CTab
						v-for="(step, index) in steps"
						:key="index"
						:title="`Step ${index + 1}: ${step.title}`"
						:disabled="step.disabled"
					>
						<div
							v-if="step.component"
							class="main-wrapper"
						>
							<component
								:is="step.component"
								:key="step.title"
								:default-data="step.formData"
								:is-edit="isEdit"
								@onChanged="handleChangedFormData(index, $event)"
							/>
						</div>
					</CTab>
				</CTabs>
			</div>
			<div class="navigation-panel">
				<div class="main-wrapper">
					<div class="d-flex justify-content-end align-items-center">
						<a
							v-if="isShowRemoveButton"
							href="#"
							class="btn-remove mr-auto"
							@click.prevent="$refs['modal-remove'].open()"
						>
							{{ removeText }}
						</a>
						<span
							v-if="!isEdit"
							class="mr-4"
						>
							Step {{ currentStepIndex + 1 }}/{{ totalStep }}
						</span>
						<CButton
							color="tertiary"
							class="transparent mr-3"
							:disabled="isSubmitting"
							@click.prevent="$emit('onCancel')"
						>
							Cancel
						</CButton>
						<CButton
							type="button"
							class="transparent"
							color="primary"
							data-test-id="button"
							:disabled="!isEnableNextStep || isSubmitting"
							@click="handleClickSubmitButton"
						>
							{{ submitText }}
						</CButton>
					</div>
				</div>
			</div>

			<!-- Modals -->
			<BaseModalConfirm
				v-if="isEdit && isConfirmUpdate"
				ref="modal-confirm"
				:is-submitting="isSubmitting"
				:title="confirmUpdateTitleText"
				:description="confirmUpdateText"
				primary-button-text="Confirm save"
				primary-button-loading-text="Saving"
				@onConfirm="$emit('onSubmit')"
			/>
			<BaseModalConfirmDelete
				v-if="isShowRemoveButton"
				ref="modal-remove"
				:handle-remove="handleRemove"
				:title="confirmRemoveTitleText"
				:description="confirmRemoveText"
				is-confirm-required
				@onSuccess="$emit('onRemoved')"
			/>
		</div>
	</div>
</template>

<script>
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
	name: 'StepForm',

	components: {
		BaseModalConfirm,
		PageHeader,
	},

	props: {
		steps: {
			type: Array,
			default: () => [],
		},
		bundleInfo: {
			type: Object,
			default: () => {},
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		isRemovable: {
			type: Boolean,
			default: false,
		},
		handleRemove: {
			type: Function,
			default: () => {},
		},
		removeText: {
			type: String,
			default: 'Remove',
		},
		confirmRemoveTitleText: {
			type: String,
			default: 'Remove?',
		},
		confirmRemoveText: {
			type: String,
			default: 'Are you sure you want to delete?',
		},
		submitText: {
			type: String,
			default: 'Next',
		},
		isConfirmUpdate: {
			type: Boolean,
			default: false,
		},
		isExpired: {
			type: Boolean,
			default: false,
		},
		confirmUpdateTitleText: {
			type: String,
			default: 'Save confirmation?',
		},
		confirmUpdateText: {
			type: String,
			default: 'This is ongoing. Making changes will effect on storefront immediately. Are you sure you want to save?',
		},
	},

	data() {
		return {
			currentStepIndex: 0,
		};
	},

	computed: {
		totalStep() {
			return this.steps.length;
		},

		isLastStep() {
			return this.currentStepIndex === (this.totalStep - 1);
		},

		isEnableNextStep() {
			if (this.isExpired) {
				return false;
			}

			if (this.isLastStep) {
				return this.steps[this.currentStepIndex].valid ?? false;
			}

			const nextStep = this.currentStepIndex + 1;
			return !this.steps[nextStep].disabled && this.steps[this.currentStepIndex].valid;
		},

		isShowRemoveButton() {
			return this.isEdit && this.isRemovable;
		},
	},

	watch: {
		currentStepIndex(index) {
			this.$emit('onChangedStep', {
				index,
				isLastStep: this.isLastStep,
			});
		},
	},

	methods: {
		handleUpdateTab(tabIndex) {
			this.currentStepIndex = tabIndex;
		},

		handleChangedFormData(stepIndex, data) {
			const steps = [...this.steps];
			const valid = data?.valid ?? false;
			const formData = data?.formData ?? steps[stepIndex]?.formData;
			// Update steps
			this.$set(steps, stepIndex, {
				...steps[stepIndex],
				valid,
				formData,
			});

			// Toggle disabled status for next tab
			if ((steps.length - 1) > stepIndex) {
				const nextStepIndex = stepIndex + 1;
				this.$set(steps, nextStepIndex, {
					...steps[nextStepIndex],
					disabled: !valid,
				});
			}

			this.$emit('update:steps', steps);
		},

		handleClickSubmitButton() {
			// Edit
			if (this.isEdit) {
				if (this.isConfirmUpdate) {
					this.$refs['modal-confirm'].open();
				} else {
					this.$emit('onSubmit');
				}

				return;
			}

			// Create
			if (this.isLastStep) {
				this.$emit('onSubmit');

				return;
			}

			// Handle tab's disabled status
			if (this.isEnableNextStep) {
				this.currentStepIndex += 1;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.navigation-panel {
		position: fixed;
		left: rem(56);
		right: 0;
		bottom: 0;
		padding: rem(12) 0;
		box-shadow: 0 1px 2px 0 $color-gray-07, 0 4px 16px 0 $color-gray-15;
		background-color: $color-white;
		z-index: 9;

		@media (max-width: 991.98px) {
			left: 0;
		}

		::v-deep button {
			min-width: rem(80);
		}
	}
</style>
