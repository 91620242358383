<template>
	<StepForm
		:steps.sync="steps"
		:is-edit="isEdit"
		:is-submitting="isSubmitting"
		:submit-text="submitText"
		:confirm-remove-text="confirmRemoveText"
		:is-confirm-update="isPeriodStatusOngoing"
		:is-expired="isPeriodStatusExpired"
		:bundle-info="info"
		remove-text="Remove bundle set"
		confirm-remove-title-text="Remove this bundle set?"
		confirm-update-text="This bundle set is ongoing. Making changes will effect on storefront immediately. Are you sure you want to save?"
		@onChangedStep="handleChangedStep"
		@onCancel="handleCancel"
		@onSubmit="handleSubmit"
		@onRemoved="handleRemoved"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StepForm from '@/components/StepForm.vue';
import BundleSetStepGeneralInfo from '@/components/BundleSetStepGeneralInfo.vue';
import BundleSetStepCondition from '@/components/BundleSetStepCondition.vue';
import { transformedFormDataBundleSetToAPI } from '../assets/js/transform/bundleSet';
import { ROUTE_NAME } from '../enums/route';
import { BUNDLE_SET_LABEL_TITLE_TABS, BUNDLE_SET_PERIODS_STATUSES } from '../enums/bundleSet';

export default {
	name: 'BundleSetForm',

	components: {
		StepForm,
	},

	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		defaultData: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			BUNDLE_SET_PERIODS_STATUSES,

			id: Number(this.$route.params.id) || null,
			steps: [
				{
					title: BUNDLE_SET_LABEL_TITLE_TABS.GENERAL_INFO,
					valid: this.isEdit,
					disabled: false,
					component: BundleSetStepGeneralInfo,
					formData: {},
				},
				{
					title: BUNDLE_SET_LABEL_TITLE_TABS.CONDITION,
					valid: this.isEdit,
					disabled: !this.isEdit,
					component: BundleSetStepCondition,
					formData: {},
				},
			],
			isSubmitting: false,
			isLastStep: false,
		};
	},

	computed: {
		...mapGetters({
			info: 'bundleSet/getBundleGeneralInfo',
		}),

		submitText() {
			// Edit
			if (this.isEdit) {
				return this.isSubmitting ? 'Saving' : 'Save changes';
			}

			// Create
			if (this.isSubmitting) {
				return 'Creating...';
			}

			return this.isLastStep ? 'Create' : 'Next';
		},

		confirmRemoveText() {
			return this.isPeriodStatusOngoing
				? 'This bundle set is ongoing. Removing this bundle set will effect on storefront immediately. Do you still want to remove this bundle set?'
				: 'Are you sure you want to delete this bundle set?';
		},

		isPeriodStatusOngoing() {
			return this.info.periodValue === BUNDLE_SET_PERIODS_STATUSES.ON_GOING || this.info.periodValue === BUNDLE_SET_PERIODS_STATUSES.SCHEDULED;
		},

		isPeriodStatusExpired() {
			if (!this.isEdit) {
				return false;
			}

			return this.info.periodValue === BUNDLE_SET_PERIODS_STATUSES.EXPIRED;
		},
	},

	created() {
		this.extractData();
	},

	methods: {
		...mapActions({
			createBundleSet: 'bundleSet/createBundleSet',
			updateBundleSet: 'bundleSet/updateBundleSet',
		}),

		extractData() {
			if (this.defaultData) {
				// Step 1
				const step1 = this.defaultData?.step1 ?? {};
				this.$set(this.steps, 0, {
					...this.steps[0],
					formData: {
						...this.steps[0].formData,
						...step1,
					},
				});

				// Step 2
				const step2 = this.defaultData?.step2 ?? {};
				this.$set(this.steps, 1, {
					...this.steps[1],
					formData: {
						...this.steps[1].formData,
						...step2,
					},
				});
			}
		},

		handleChangedStep(data) {
			this.isLastStep = data?.isLastStep ?? false;
		},

		handleRemoved() {
			this.$router.push({ name: ROUTE_NAME.BUNDLE_SET_LIST });
		},

		handleCancel() {
			this.$router.push({ name: ROUTE_NAME.BUNDLE_SET_LIST });
		},

		async handleSubmit() {
			try {
				this.isSubmitting = true;
				const params = transformedFormDataBundleSetToAPI(this.steps);
				if (this.isEdit) {
					await this.updateBundleSet({
						id: this.id,
						params,
					});
				} else {
					await this.createBundleSet(params);
					this.$router.push({ name: ROUTE_NAME.BUNDLE_SET_LIST });
				}
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>

